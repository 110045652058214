import Grid from "@mui/joy/Grid";
import {
  LinePlot,
  MarkPlot,
  lineElementClasses,
} from "@mui/x-charts/LineChart";
import { MaterialEmbed } from "../../../frameworks/joy/material_embed";
import { TotalCogs } from "./total_cogs";
import FormControl from "@mui/joy/FormControl";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { ResponsiveChartContainer } from "@mui/x-charts/ResponsiveChartContainer";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import { ChartsTooltip } from "@mui/x-charts/ChartsTooltip";
import { ChartsGrid } from "@mui/x-charts/ChartsGrid";
import { IOHFData } from "./inventory_on_hand_forecast_data";
import { OrchestratorRun } from "../../../services/api/__generated__/backend_gateway-types";
// import Select from "@mui/joy/Select";
// import Option from "@mui/joy/Option";
// import Chip from "@mui/joy/Chip";
import { runDescription } from "./run_description";
import { useMemo } from "react";
import { AllSeriesType } from "@mui/x-charts/models/seriesType";
import { ChartsLegend } from "@mui/x-charts/ChartsLegend";

export const InventoryOnHandForecastTotalCogsComponent = (props: {
  totalCogs: TotalCogs[];
  loading: boolean;
  downloadSummaryCsvBlob: () => void;
  downloadDetailedCsvBlob: () => void;
  // availableRunIds: number[];
  allRuns: OrchestratorRun[];
  // setInventoryOnHandForecast: (runIds: number[]) => void;
  forecastedTotalCogs: Record<number, IOHFData[]>;
}) => {
  const {
    totalCogs,
    loading,
    downloadSummaryCsvBlob,
    downloadDetailedCsvBlob,
    // availableRunIds,
    allRuns,
    // setInventoryOnHandForecast,
    forecastedTotalCogs,
  } = props;
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format;
  const forecastedTotalCogsRunIds = Object.keys(forecastedTotalCogs).map(
    (k) => `${k}`
  );

  const dates = useMemo(() => {
    const allDates = totalCogs.map((tc) => tc.date);
    forecastedTotalCogsRunIds.forEach((runId) => {
      allDates.push(
        ...forecastedTotalCogs[parseInt(runId)].map((tc) => new Date(tc.date))
      );
    });
    return allDates.filter(
      (date, i, self) =>
        self.findIndex((d) => d.getTime() === date.getTime()) === i
    );
  }, [totalCogs, forecastedTotalCogs, forecastedTotalCogsRunIds]);

  const pastTotalCogs: Array<number | null> = useMemo(
    () =>
      dates.map(
        (d) => totalCogs.find((tc) => tc.date === d)?.totalCogs || null
      ),
    [dates, totalCogs]
  );

  const maxPastDate = useMemo(
    () => new Date(Math.max(...totalCogs.map((tc) => tc.date.getTime()))),
    [totalCogs]
  );

  const forecastTotalCogss: Record<
    string,
    Array<number | null>
  > = useMemo(() => {
    const newForecastTotalCogss: Record<string, Array<number | null>> = {};
    forecastedTotalCogsRunIds.forEach((runId) => {
      newForecastTotalCogss[runId] = dates.map((d, i) => {
        if (d > maxPastDate) {
          const foundTc = forecastedTotalCogs[parseInt(runId)].find((tc, j) => {
            return tc.date.getTime() === d.getTime() && tc.date > maxPastDate;
          });
          return foundTc?.total_cogs_on_hand || null;
        }
        return null;
      });
    });
    return newForecastTotalCogss;
  }, [dates, forecastedTotalCogs, forecastedTotalCogsRunIds, maxPastDate]);

  const allSeries: AllSeriesType[] = useMemo(() => {
    const newAllSeries = [
      {
        id: "past",
        label: "Past",
        type: "line" as "line",
        data: pastTotalCogs,
        showMark: false,
        valueFormatter: (v: number | bigint | null) =>
          v === null ? "" : currencyFormatter(v),
      },
    ];
    forecastedTotalCogsRunIds.forEach((runId) => {
      newAllSeries.push({
        id: runId,
        label: `Forcast (#${runId}) ${runDescription(
          allRuns,
          parseInt(runId)
        )}`,
        type: "line" as "line",
        data: forecastTotalCogss[runId],
        showMark: false,
        valueFormatter: (v: number | bigint | null) =>
          v === null ? "" : currencyFormatter(v),
      });
    });
    return newAllSeries;
  }, [
    pastTotalCogs,
    forecastedTotalCogsRunIds,
    forecastTotalCogss,
    currencyFormatter,
    allRuns,
  ]);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        flexGrow: 1,
        // maxWidth: Math.min(Math.max(800, (totalCogs.length + 1) * 300)),
      }}
    >
      <Grid xl={12} lg={12}>
        {totalCogs.length > 0 && (
          <MaterialEmbed>
            <ResponsiveChartContainer
              xAxis={[
                {
                  data: dates,
                  scaleType: "time",
                  valueFormatter: (date) =>
                    [
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ][
                      new Date(
                        date.getTime() + date.getTimezoneOffset() * 60000
                      ).getMonth()
                    ],
                  id: "x-axis-id",
                },
              ]}
              yAxis={[
                {
                  scaleType: "linear",
                  label: "Total COGS ($)",
                  id: "y-axis-id",
                  valueFormatter: (v) =>
                    v === null ? "" : `$${Math.floor(v / 1000000)}M`,
                },
              ]}
              series={[...allSeries]}
              height={300}
              margin={{ left: 100 }}
              sx={{
                [`& .${axisClasses.left} .${axisClasses.label}`]: {
                  transform: "translateX(-50px)",
                },
                [`& .${lineElementClasses.root}`]: {
                  strokeWidth: 2,
                },
                ...Object.assign(
                  {},
                  ...forecastedTotalCogsRunIds.map((runId) => ({
                    [`& .MuiLineElement-series-${runId}`]: {
                      strokeDasharray: "3 1",
                      strokeWidth: 2,
                      opacity: 0.7,
                    },
                  }))
                ),
              }}
            >
              <ChartsGrid horizontal />
              <LinePlot />
              <ChartsXAxis axisId="x-axis-id" />
              <ChartsYAxis axisId="y-axis-id" />
              <MarkPlot />
              <ChartsTooltip />
              <ChartsLegend
                direction="row"
                position={{
                  vertical: "top",
                  horizontal: "middle",
                }}
                slotProps={{
                  legend: {
                    labelStyle: { fontSize: 10, padding: 0 },
                  },
                }}
              />
            </ResponsiveChartContainer>
          </MaterialEmbed>
        )}
      </Grid>
      {/* <Grid xl={12} lg={12}>
        <Select
          multiple
          renderValue={(selected) => (
            <Box sx={{ display: "flex", gap: "0.25rem" }}>
              {selected.map((selectedOption) => (
                <Chip
                  variant="soft"
                  color="primary"
                  key={`${selectedOption.value}`}
                >
                  {`${selectedOption.value}`}
                </Chip>
              ))}
            </Box>
          )}
          sx={{
            minWidth: "15rem",
          }}
          slotProps={{
            listbox: {
              sx: {
                width: "100%",
              },
            },
          }}
          onChange={(_, newValues: {}[]) =>
            setInventoryOnHandForecast(newValues as number[])
          }
        >
          {availableRunIds.map((runId) => {
            return (
              <Option key={runId} value={runId}>
                <Chip variant="soft" color="primary">
                  {runId}
                </Chip>
                {runDescription(runLogs, runId)}
              </Option>
            );
          })}
        </Select>
      </Grid> */}
      <Grid xl={12} lg={12}>
        {totalCogs.length > 0 && (
          <FormControl
            sx={{
              m: 2,
              pt: 2,
            }}
          >
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
              <Button
                disabled={loading}
                color="primary"
                variant="solid"
                size="lg"
                onClick={downloadSummaryCsvBlob}
              >
                Export: Summary Data
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="solid"
                size="lg"
                onClick={downloadDetailedCsvBlob}
              >
                Export: Detailed Data
              </Button>
            </Box>
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
};
