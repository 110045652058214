import { OrchestratorRun } from "../../../services/api/__generated__/backend_gateway-types";

export const runDescription = (
  allRuns: OrchestratorRun[],
  runId: number
): string => {
  const runLog = allRuns.find((r) => r?.oldRunId === runId);
  if (runLog === undefined) {
    // console.error("runDescription: run not found for runId", runId);
    return "";
  }
  return `${runLog.name || ""}${
    runLog.name && runLog.description ? " - " : ""
  }${runLog.description || ""}`;
};
