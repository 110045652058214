import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../../contexts/api/context";
import { AddEnginePresetComponent } from "./component";
import {
  useCreateMyEnginePresetMutation,
  useGetMyCustomerQuery,
} from "../../../services/api/__generated__/backend_gateway-types";

export const AddEnginePresetContainer = () => {
  const { apiService } = useContext(ApiContext);
  const [createMyEnginePreset, { loading: createLoading, error: createError }] =
    useCreateMyEnginePresetMutation({
      client: apiService.getClient(),
    });
  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useGetMyCustomerQuery({
    client: apiService.getClient(),
  });
  const navigate = useNavigate();
  const [reloading, setReloading] = useState<boolean>(false);

  const navigateToEnginePresets = () => {
    navigate("/engine_presets", { replace: true });
  };

  const createPreset = async (
    presetName: string,
    presetDescription: string,
    orderToDeliveryDaysOffset: number | null,
    weightingMethod: string | null,
    orderSimulationEndDate: string | null,
    historicalDatePatterns: boolean | null,
    weightingWindowDays: number | null,
    bindToSKUOverrides: boolean | null,
    bindToForecastUpload: boolean | null,
    modifyExistingPos: boolean | null,
    dyeYieldLoss: number | null,
    sewYieldLoss: number | null,
    cutYieldLoss: number | null,
    weeksOfSupply: number | null,
    useBufferForecast: boolean | null,
    desiredMinWeeksOnHand: number | null,
    leadTimeInWeeks: number | null,
    assumedCustomerGrowth: number | null,
    excludeForecast: boolean | null,
    excludeYieldPlan: boolean | null,
    truckOrderToPackDays: number | null,
    onHoldToAvailableDays: number | null,
    productionStartToEndDays: number | null,
    darkRoastRuntime: number | null,
    mediumRoastRuntime: number | null,
    lightRoastRuntime: number | null,
    defaultChangeoverTime: number | null,
    decafChangeoverTime: number | null,
    turnOnProductionBatching: boolean | null,
    organicMillingNextProductionRun: string | null,
    cnpNextProductionRun: string | null,
    taraNextProductionRun: string | null,
    auroraNextProductionRun: string | null,
    firmWindowLookAhead: number | null,
    trendLookbackWindow: number | null
  ) => {
    setReloading(true);
    await createMyEnginePreset({
      variables: {
        enginePreset: {
          name: presetName,
          description: presetDescription,
          ...(orderToDeliveryDaysOffset !== null && {
            orderToDeliveryDaysOffset,
          }),
          ...(weightingMethod !== null && { weightingMethod }),
          ...(orderSimulationEndDate !== null && { orderSimulationEndDate }),
          ...(historicalDatePatterns !== null && { historicalDatePatterns }),
          ...(weightingWindowDays !== null && { weightingWindowDays }),
          ...(bindToSKUOverrides !== null && { bindToSKUOverrides }),
          ...(bindToForecastUpload !== null && { bindToForecastUpload }),
          ...(modifyExistingPos !== null && { modifyExistingPos }),
          ...(dyeYieldLoss !== null && { dyeYieldLoss }),
          ...(sewYieldLoss !== null && { sewYieldLoss }),
          ...(cutYieldLoss !== null && { cutYieldLoss }),
          ...(weeksOfSupply !== null && { weeksOfSupply }),
          ...(useBufferForecast !== null && { useBufferForecast }),
          ...(desiredMinWeeksOnHand !== null && { desiredMinWeeksOnHand }),
          ...(leadTimeInWeeks !== null && { leadTimeInWeeks }),
          ...(assumedCustomerGrowth !== null && { assumedCustomerGrowth }),
          ...(excludeForecast !== null && { excludeForecast }),
          ...(excludeYieldPlan !== null && { excludeYieldPlan }),
          ...(truckOrderToPackDays !== null && { truckOrderToPackDays }),
          ...(onHoldToAvailableDays !== null && { onHoldToAvailableDays }),
          ...(productionStartToEndDays !== null && {
            productionStartToEndDays,
          }),
          ...(darkRoastRuntime !== null && { darkRoastRuntime }),
          ...(mediumRoastRuntime !== null && { mediumRoastRuntime }),
          ...(lightRoastRuntime !== null && { lightRoastRuntime }),
          ...(defaultChangeoverTime !== null && { defaultChangeoverTime }),
          ...(decafChangeoverTime !== null && { decafChangeoverTime }),
          ...(turnOnProductionBatching !== null && {
            turnOnProductionBatching,
          }),
          ...(organicMillingNextProductionRun !== null && {
            organicMillingNextProductionRun,
          }),
          ...(cnpNextProductionRun !== null && { cnpNextProductionRun }),
          ...(taraNextProductionRun !== null && { taraNextProductionRun }),
          ...(auroraNextProductionRun !== null && { auroraNextProductionRun }),
          ...(firmWindowLookAhead !== null && { firmWindowLookAhead }),
          ...(trendLookbackWindow !== null && { trendLookbackWindow }),
          default: false,
          customerId: "",
        },
      },
    });
    await apiService.getClient().refetchQueries({
      include: ["GetMyEnginePresets"],
    });
    navigate("/engine_presets", { replace: true });
    setReloading(false);
  };

  if (customerError) {
    console.error(
      "AddEnginePresetContainer: useGetMyCustomerQuery fetch error",
      customerError
    );
  }

  if (createError) {
    console.error(
      "AddEnginePresetContainer: useCreateMyEnginePresetMutation fetch error",
      createError
    );
  }

  return (
    <AddEnginePresetComponent
      navigateBack={navigateToEnginePresets}
      createPreset={createPreset}
      createLoading={createLoading || reloading}
      loading={customerLoading}
      customer={customerData?.getMyCustomer || null}
    />
  );
};
