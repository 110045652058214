import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary, {
  accordionSummaryClasses,
} from "@mui/joy/AccordionSummary";
import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import LinearProgress from "@mui/joy/LinearProgress";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import { JoyCompatibleDatePicker } from "../../../components/date_picker";
import { MaterialEmbed } from "../../../frameworks/joy/material_embed";
import { OrchestratorRun } from "../../../services/api/__generated__/backend_gateway-types";
import { PeriodType } from "../period_type";
import { DiffParams, SalesMeasure } from "../diff_params";
import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import Switch from "@mui/joy/Switch";

dayjs.extend(utc);

export const DemandDiffControlComponent = (props: {
  diffParams: DiffParams;
  updateDiffParams: (params: DiffParams) => void;
  updateRuns: (params: DiffParams) => void;
  loading: boolean;
  error: string | null;
  runs: OrchestratorRun[];
  excelExport: () => void;
}) => {
  const {
    diffParams,
    updateDiffParams,
    updateRuns,
    loading,
    runs,
    excelExport,
  } = props;

  return (
    <MaterialEmbed>
      <AccordionGroup
        size="lg"
        variant="outlined"
        sx={{
          m: 2,
          borderRadius: "sm",
          [`& .${accordionSummaryClasses.button}:hover`]: {
            borderRadius: "sm",
          },
        }}
      >
        <Accordion defaultExpanded>
          <AccordionSummary
            sx={{
              borderBottom: (theme) =>
                `1px solid ${theme.vars.palette.divider}`,
            }}
          >
            Run Comparison - Controls
          </AccordionSummary>
          <AccordionDetails
            sx={{ pt: 2, bgcolor: "atomic.highlightBackground" }}
          >
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel htmlFor="select-run-0" id="select-run-0-label">
                    Select a Run
                  </FormLabel>
                  <Select
                    value={diffParams.runId0}
                    placeholder="Demand forecast"
                    slotProps={{
                      button: {
                        id: "select-run-0",
                        "aria-labelledby": "select-label select-run-0",
                      },
                    }}
                  >
                    {runs.map((run, i) => (
                      <Option
                        key={run.oldRunId || i}
                        value={
                          run.oldRunId === null ? "INVALID" : `${run.oldRunId}`
                        }
                        onClick={() => {
                          updateRuns({
                            ...diffParams,
                            runId0:
                              run.oldRunId === null ? null : `${run.oldRunId}`,
                            runId1: null,
                          });
                        }}
                        label={
                          <>
                            <Chip variant="solid">{`${run.oldRunId}`}</Chip>{" "}
                            <Typography sx={{ ml: 1 }}>
                              {run.createdAt &&
                                ` ${
                                  new Date(run.createdAt).getUTCMonth() + 1
                                }/${new Date(
                                  run.createdAt
                                ).getUTCDate()}/${new Date(
                                  run.createdAt
                                ).getUTCFullYear()}`}
                            </Typography>
                            <Typography sx={{ ml: 1, fontWeight: 800 }}>
                              {!run.name ? "" : <b>{` ${run.name}`}</b>}
                            </Typography>
                            <Typography sx={{ ml: 1 }}>
                              {!run.description ? "" : `${run.description}`}
                            </Typography>
                          </>
                        }
                      >
                        <Chip variant="outlined">{run.oldRunId}</Chip>{" "}
                        {run.createdAt &&
                          `${
                            new Date(run.createdAt).getUTCMonth() + 1
                          }/${new Date(run.createdAt).getUTCDate()}/${new Date(
                            run.createdAt
                          ).getUTCFullYear()}`}
                        {!run.name ? "" : <b>{` ${run.name}`}</b>}
                        {!run.description ? "" : ` ${run.description}`}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel htmlFor="select-run-1" id="select-run-1-label">
                    Select a Run for Comparison{" "}
                    <Chip size="sm" variant="soft">
                      Optional
                    </Chip>
                  </FormLabel>
                  <Select
                    disabled={diffParams.runId0 === null}
                    value={diffParams.runId1}
                    placeholder="Select Run"
                    slotProps={{
                      button: {
                        id: "select-run-1",
                        "aria-labelledby": "select-label select-run-1",
                      },
                    }}
                  >
                    <Option
                      value=""
                      onClick={() => {
                        updateRuns({
                          ...diffParams,
                          runId1: null,
                        });
                      }}
                    >
                      Select none
                    </Option>
                    {runs
                      .filter(
                        (run) =>
                          (run.oldRunId || 0) < parseInt(diffParams.runId0!)
                      )
                      .map((run) => (
                        <Option
                          key={run.oldRunId || -1}
                          value={
                            run.oldRunId === null
                              ? "INVALID"
                              : `${run.oldRunId}`
                          }
                          onClick={() => {
                            updateRuns({
                              ...diffParams,
                              runId1:
                                run.oldRunId === null
                                  ? null
                                  : `${run.oldRunId}`,
                              minDate: new Date(run.createdAt || "1970-01-01"),
                              maxDate: null,
                              aggregatePeriodType: PeriodType.WEEK,
                            });
                          }}
                          label={
                            <>
                              <Chip variant="solid">{`${run.oldRunId}`}</Chip>{" "}
                              <Typography sx={{ ml: 1 }}>
                                {run.createdAt &&
                                  ` ${
                                    new Date(run.createdAt).getUTCMonth() + 1
                                  }/${new Date(
                                    run.createdAt
                                  ).getUTCDate()}/${new Date(
                                    run.createdAt
                                  ).getUTCFullYear()}`}
                              </Typography>
                              <Typography sx={{ ml: 1, fontWeight: 800 }}>
                                {!run.name ? "" : <b>{` ${run.name}`}</b>}
                              </Typography>
                              <Typography sx={{ ml: 1 }}>
                                {!run.description ? "" : `${run.description}`}
                              </Typography>
                            </>
                          }
                        >
                          <Chip variant="outlined">{run.oldRunId}</Chip>{" "}
                          {run.createdAt &&
                            `${
                              new Date(run.createdAt).getUTCMonth() + 1
                            }/${new Date(
                              run.createdAt
                            ).getUTCDate()}/${new Date(
                              run.createdAt
                            ).getUTCFullYear()}`}
                          {!run.name ? "" : <b>{` ${run.name}`}</b>}
                          {!run.description ? "" : ` ${run.description}`}
                        </Option>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel>Set the Comparison Start Date</FormLabel>
                  <JoyCompatibleDatePicker
                    disabled={diffParams.runId0 === null}
                    value={dayjs.utc(diffParams.minDate)}
                    disablePast={false}
                    maxDate={dayjs("12/31/2026")}
                    onChange={(newNalue) =>
                      updateDiffParams({
                        ...diffParams,
                        minDate: newNalue?.toDate() || new Date(),
                      })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel>Set the Comparison End Date</FormLabel>
                  <JoyCompatibleDatePicker
                    disabled={diffParams.runId0 === null}
                    value={dayjs.utc(diffParams.maxDate)}
                    disablePast={false}
                    maxDate={dayjs("12/31/2026")}
                    onChange={(newNalue) =>
                      updateDiffParams({
                        ...diffParams,
                        maxDate: newNalue?.toDate() || new Date(),
                      })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    htmlFor="select-period-type"
                    id="select-period-type-label"
                  >
                    Select Date Hierarchy
                  </FormLabel>
                  <Select
                    value={`${diffParams.aggregatePeriodType}`}
                    placeholder="Monthly or Weekly"
                    slotProps={{
                      button: {
                        id: "select-period-type",
                        "aria-labelledby": "select-label select-period-type",
                      },
                    }}
                  >
                    <Option
                      value={`${PeriodType.WEEK}`}
                      onClick={() => {
                        updateDiffParams({
                          ...diffParams,
                          aggregatePeriodType: PeriodType.WEEK,
                        });
                      }}
                    >
                      Aggregate by Week
                    </Option>
                    <Option
                      value={`${PeriodType.MONTH}`}
                      onClick={() => {
                        updateDiffParams({
                          ...diffParams,
                          aggregatePeriodType: PeriodType.MONTH,
                        });
                      }}
                    >
                      Aggregate by Month
                    </Option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel htmlFor="select-filter" id="select-filter-label">
                    Sales Measure
                  </FormLabel>
                  <Select
                    value={`${diffParams.salesMeasure}`}
                    placeholder="Quantity or Revenue"
                    slotProps={{
                      button: {
                        id: "select-filter",
                        "aria-labelledby": "select-label select-filter",
                      },
                    }}
                  >
                    <Option
                      value={`${SalesMeasure.QUANTITY}`}
                      onClick={() => {
                        updateDiffParams({
                          ...diffParams,
                          salesMeasure: SalesMeasure.QUANTITY,
                        });
                      }}
                    >
                      Sales Orders (Quantity)
                    </Option>
                    <Option
                      value={`${SalesMeasure.REVENUE}`}
                      onClick={() => {
                        updateDiffParams({
                          ...diffParams,
                          salesMeasure: SalesMeasure.REVENUE,
                        });
                      }}
                    >
                      Revenue
                    </Option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} md={6} sx={{ pt: 1.5 }}>
                <FormControl>
                  <Typography
                    component="label"
                    startDecorator={
                      <Switch
                        checked={diffParams.heatMap || false}
                        onChange={(event) =>
                          updateDiffParams({
                            ...diffParams,
                            heatMap: event.target.checked,
                          })
                        }
                      />
                    }
                  >
                    {diffParams.heatMap
                      ? "Heatmap Enabled"
                      : "Heatmap Disabled"}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid xs={6} md={3}>
                <FormControl>
                  <Button onClick={excelExport} size="sm" variant="outlined">
                    Export Table as .xlsx
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </MaterialEmbed>
  );
};
