import { SyntheticEvent, useRef, useState } from "react";
import dayjs from "dayjs";
import Chip from "@mui/joy/Chip";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import LinearProgress from "@mui/joy/LinearProgress";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Typography from "@mui/joy/Typography";
import {
  MyCustomer,
  EnginePreset,
  OrchestratorRun,
} from "../../../services/api/__generated__/backend_gateway-types";
import { RightPaneBackButton } from "../../../components/right_pane_back_button";
import { RightPaneFrame } from "../../../components/right_pane_frame";
import { RightPaneTitle } from "../../../components/right_pane_title";

export const RunEnginePresetComponent = (props: {
  preset: EnginePreset | null;
  loading: boolean;
  navigateBack: () => void;
  runPreset: (loadDemandOfRunId: number | null) => Promise<void>;
  runningInProgress: boolean;
  runDisabled: boolean;
  customer: MyCustomer | null;
  theEngineScriptsRuns: OrchestratorRun[];
  runIdsWithOutputs: number[];
}) => {
  const {
    preset,
    loading,
    navigateBack,
    runPreset,
    runningInProgress,
    runDisabled,
    customer,
    theEngineScriptsRuns,
    runIdsWithOutputs,
  } = props;

  const [loadDemandOfRunId, setLoadDemandOfRunId] = useState<number | null>(
    null
  );
  const [calculateDemand, setCalculateDemand] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  if (!customer?.features?.EnginePresets) {
    return null;
  }

  return (
    <RightPaneFrame>
      <RightPaneBackButton labelText="Close" onClick={navigateBack} />
      <RightPaneTitle>Run Selected Preset</RightPaneTitle>
      <Typography level="body-sm" sx={{ pb: 2 }}>
        {runningInProgress
          ? "Currently a run is in progress, please wait for it to finish before starting a new one."
          : "Start a new run using the selected value preset for the engine calculations."}
      </Typography>

      {loading && <LinearProgress />}

      {preset && (
        <>
          <Box
            sx={{
              py: 2,
              display: "grid",
              gap: 4,
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {customer?.features?.EngineLoadLockedDemandForecast && (
              <>
                <Typography level="body-md" sx={{ pb: 0.5 }}>
                  Demand Forecast
                </Typography>
                <FormControl>
                  <FormLabel>Forecast calculation</FormLabel>
                  <RadioGroup
                    name="radio-buttons-group"
                    value={calculateDemand === true ? "CALCULATE" : "LOAD"}
                    onChange={(event) => {
                      const calculate = event.target.value === "CALCULATE";
                      setCalculateDemand(calculate);
                      if (calculate) {
                        setLoadDemandOfRunId(null);
                      }
                    }}
                  >
                    <Radio
                      value="CALCULATE"
                      label="Newly Calculate Forecast"
                      variant="outlined"
                    />
                    <Radio
                      value="LOAD"
                      label="Load Forecast from Prior Run"
                      variant="outlined"
                    />
                  </RadioGroup>
                </FormControl>
                {!calculateDemand && (
                  <FormControl>
                    <FormLabel>Select Prior Demand Forecast</FormLabel>
                    <Select
                      placeholder="Prior Demand Forecast"
                      defaultValue=""
                      value={
                        loadDemandOfRunId === null
                          ? "CALCULATE"
                          : `${loadDemandOfRunId}`
                      }
                      onChange={(
                        event: SyntheticEvent | null,
                        newValue: string | null
                      ) => {
                        setLoadDemandOfRunId(
                          newValue !== null && newValue !== "CALCULATE"
                            ? parseInt(newValue)
                            : null
                        );
                      }}
                      sx={{ minWidth: 200 }}
                    >
                      {/* {theEngineScriptsRuns
                        .filter((run) =>
                          runIdsWithOutputs.includes(run.oldRunId || -1)
                        )
                        .map((run) => (
                          <Option value={`${run.oldRunId}`} key={run.oldRunId}>
                            From Run: {run.name || ""} - {run.description || ""}{" "}
                            [{run.oldRunId}]
                          </Option>
                        ))} */}
                      {runIdsWithOutputs.map((oldRunId) => {
                        const run = theEngineScriptsRuns.find(
                          (r) => r.oldRunId === oldRunId
                        );
                        return (
                          <Option value={`${oldRunId}`} key={oldRunId}>
                            From Run: <Chip size="sm">{oldRunId}</Chip>
                            {run && ` ${run.name || ""}`}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </>
            )}
            <Typography level="body-md" sx={{ pb: 0.5 }}>
              Preset details (readonly)
            </Typography>

            <FormControl>
              <FormLabel>Preset name</FormLabel>
              <Input value={preset.name} disabled variant="solid" />
            </FormControl>
            <FormControl>
              <Input value={preset.description} disabled variant="solid" />
            </FormControl>
            {customer?.features?.VarWeightingMethod && (
              <>
                <Typography level="body-md" sx={{ pb: 0.5 }}>
                  SKU Assignment
                </Typography>
                <FormControl>
                  <FormLabel>Weighting Method</FormLabel>
                  <Input
                    value={preset.weightingMethod || ""}
                    disabled
                    variant="solid"
                  />
                </FormControl>
              </>
            )}
            {customer?.features?.VarWeightingWindowDays && (
              <FormControl>
                <FormLabel>Weighting Window</FormLabel>
                <Input
                  type="number"
                  value={preset.weightingWindowDays || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}

            {customer?.features?.VarOrderSimulationEndDate && (
              <>
                <Typography level="body-md" sx={{ pb: 0.5 }}>
                  Order Simulation End Date
                </Typography>
                <FormControl>
                  <Input
                    value={
                      preset.orderSimulationEndDate
                        ? dayjs(preset.orderSimulationEndDate).format(
                            "MM-DD-YYYY"
                          )
                        : ""
                    }
                    disabled
                    variant="solid"
                  />
                </FormControl>
              </>
            )}
            {(customer?.features?.VarHistoricalDatePatterns ||
              customer?.features?.VarBindToSKUOverrides ||
              customer?.features?.VarBindToForecastUpload) && (
              <Typography level="body-lg" sx={{ pb: 1 }}>
                Order Simulation Feature Selection
              </Typography>
            )}
            {customer?.features?.VarHistoricalDatePatterns && (
              <FormControl>
                <FormLabel>Historical Date Patterns</FormLabel>
                <Typography component="label">
                  {preset.historicalDatePatterns ? "Enabled" : "Disabled"}
                </Typography>
              </FormControl>
            )}

            {customer?.features?.VarBindToSKUOverrides && (
              <FormControl>
                <FormLabel>Bind to SKU Overrides</FormLabel>
                <Typography component="label">
                  {preset.bindToSKUOverrides ? "Enabled" : "Disabled"}
                </Typography>
              </FormControl>
            )}

            {customer?.features?.VarBindToForecastUpload && (
              <FormControl>
                <FormLabel>Bind to Forecast Upload</FormLabel>
                <Typography component="label">
                  {preset.bindToForecastUpload ? "Enabled" : "Disabled"}
                </Typography>
              </FormControl>
            )}

            {customer?.features?.VarModifyExistingPos && (
              <FormControl>
                <FormLabel>Allow for Modifications to Existing POs</FormLabel>
                <Typography component="label">
                  {preset.modifyExistingPos ? "Enabled" : "Disabled"}
                </Typography>
              </FormControl>
            )}
            {(customer?.features?.VarDyeYieldLoss ||
              customer?.features?.VarSewYieldLoss ||
              customer?.features?.VarCutYieldLoss) && (
              <Typography level="body-lg" sx={{ pb: 1 }}>
                Yield Loss Assumptions
              </Typography>
            )}

            {customer?.features?.VarDyeYieldLoss && (
              <FormControl>
                <FormLabel>Dye Yield Loss</FormLabel>
                <Input
                  endDecorator={"%"}
                  disabled
                  variant="solid"
                  value={preset.dyeYieldLoss || ""}
                />
              </FormControl>
            )}

            {customer?.features?.VarSewYieldLoss && (
              <FormControl>
                <FormLabel>Sew Yield Loss</FormLabel>
                <Input
                  endDecorator={"%"}
                  disabled
                  variant="solid"
                  value={preset.sewYieldLoss || ""}
                />
              </FormControl>
            )}
            {customer?.features?.VarCutYieldLoss && (
              <FormControl>
                <FormLabel>Cut Yield Loss</FormLabel>
                <Input
                  endDecorator={"%"}
                  value={preset.cutYieldLoss || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {customer?.features?.VarWeeksOfSupply && (
              <FormControl>
                <FormLabel>Weeks of Supply</FormLabel>
                <Input
                  value={preset.weeksOfSupply || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {customer?.features?.VarUseBufferForecast && (
              <FormControl>
                <FormLabel>Use Buffer Forecast</FormLabel>
                <Typography component="label">
                  {preset.useBufferForecast ? "Enabled" : "Disabled"}
                </Typography>
              </FormControl>
            )}
            {customer?.features?.VarDesiredMinWeeksOnHand && (
              <FormControl>
                <FormLabel>Desired Min Weeks On Hand</FormLabel>
                <Input
                  value={preset.desiredMinWeeksOnHand || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {customer?.features?.VarLeadTimeInWeeks && (
              <FormControl>
                <FormLabel>Lead Time in Weeks</FormLabel>
                <Input
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={preset.leadTimeInWeeks || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {customer?.features?.VarAssumedCustomerGrowth && (
              <FormControl>
                <FormLabel>Assumed Customer Growth</FormLabel>
                <Input
                  endDecorator={"%"}
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={preset.assumedCustomerGrowth || 0}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {customer?.features?.VarExcludeForecast && (
              <FormControl>
                <FormLabel>Exclude Forecast</FormLabel>
                <Typography component="label">
                  {preset.excludeForecast ? "Enabled" : "Disabled"}
                </Typography>
              </FormControl>
            )}
            {customer?.features?.VarExcludeYieldPlan && (
              <FormControl>
                <FormLabel>Exclude Yield Plan</FormLabel>
                <Typography component="label">
                  {preset.excludeYieldPlan ? "Enabled" : "Disabled"}
                </Typography>
              </FormControl>
            )}
            {customer?.features?.VarTruckOrderToPackDays && (
              <FormControl>
                <FormLabel>Truck Order to Pack Days</FormLabel>
                <Input
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={preset.truckOrderToPackDays || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {customer?.features?.VarOnHoldToAvailableDays && (
              <FormControl>
                <FormLabel>On Hold to Available Days</FormLabel>
                <Input
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={preset.onHoldToAvailableDays || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {customer?.features?.VarProductionStartToEndDays && (
              <FormControl>
                <FormLabel>Production Start to End Days</FormLabel>
                <Input
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={preset.productionStartToEndDays || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {customer?.features?.VarDarkRoastRuntime && (
              <FormControl>
                <FormLabel>Dark Roast Runtime</FormLabel>
                <Input
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={preset.darkRoastRuntime || ""}
                  endDecorator={"minutes"}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {customer?.features?.VarMediumRoastRuntime && (
              <FormControl>
                <FormLabel>Medium Roast Runtime</FormLabel>
                <Input
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={preset.mediumRoastRuntime || ""}
                  endDecorator={"minutes"}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {customer?.features?.VarLightRoastRuntime && (
              <FormControl>
                <FormLabel>Light Roast Runtime</FormLabel>
                <Input
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={preset.lightRoastRuntime || ""}
                  endDecorator={"minutes"}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {customer?.features?.VarDefaultChangeoverTime && (
              <FormControl>
                <FormLabel>Default Changeover Time</FormLabel>
                <Input
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={preset.defaultChangeoverTime || ""}
                  endDecorator={"minutes"}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {customer?.features?.VarDecafChangeoverTime && (
              <FormControl>
                <FormLabel>Decaf Changeover Time</FormLabel>
                <Input
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={preset.decafChangeoverTime || ""}
                  endDecorator={"minutes"}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {customer?.features?.VarOrganicMillingNextProductionRun && (
              <>
                <Typography level="body-md" sx={{ pb: 0.5 }}>
                  Organic Milling Next Production Run
                </Typography>
                <FormControl>
                  <Input
                    value={
                      preset.organicMillingNextProductionRun
                        ? dayjs(preset.organicMillingNextProductionRun).format(
                            "MM-DD-YYYY"
                          )
                        : ""
                    }
                    disabled
                    variant="solid"
                  />
                </FormControl>
              </>
            )}
            {customer?.features?.VarCnpNextProductionRun && (
              <>
                <Typography level="body-md" sx={{ pb: 0.5 }}>
                  Cnp Next Production Run
                </Typography>
                <FormControl>
                  <Input
                    value={
                      preset.cnpNextProductionRun
                        ? dayjs(preset.cnpNextProductionRun).format(
                            "MM-DD-YYYY"
                          )
                        : ""
                    }
                    disabled
                    variant="solid"
                  />
                </FormControl>
              </>
            )}
            {customer?.features?.VarTaraNextProductionRun && (
              <>
                <Typography level="body-md" sx={{ pb: 0.5 }}>
                  Tara Next Production Run
                </Typography>
                <FormControl>
                  <Input
                    value={
                      preset.taraNextProductionRun
                        ? dayjs(preset.taraNextProductionRun).format(
                            "MM-DD-YYYY"
                          )
                        : ""
                    }
                    disabled
                    variant="solid"
                  />
                </FormControl>
              </>
            )}
            {customer?.features?.VarAauroraNextProductionRun && (
              <>
                <Typography level="body-md" sx={{ pb: 0.5 }}>
                  Aaurora Next Production Run
                </Typography>
                <FormControl>
                  <Input
                    value={
                      preset.auroraNextProductionRun
                        ? dayjs(preset.auroraNextProductionRun).format(
                            "MM-DD-YYYY"
                          )
                        : ""
                    }
                    disabled
                    variant="solid"
                  />
                </FormControl>
              </>
            )}
            {customer?.features?.VarFirmWindowLookAhead && (
              <FormControl>
                <FormLabel>Firm Window Look Ahead Days</FormLabel>
                <Input
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={preset.firmWindowLookAhead || ""}
                  endDecorator={"days"}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {customer?.features?.VarTrendLookbackWindow && (
              <FormControl>
                <FormLabel>Trend Lookback Window</FormLabel>
                <Input
                  type="number"
                  endDecorator={"weeks"}
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  disabled
                  value={preset.trendLookbackWindow || ""}
                />
                <FormHelperText>Time period</FormHelperText>
              </FormControl>
            )}
          </Box>

          <FormControl
            sx={{
              mt: 2,
            }}
          >
            <Button
              onClick={() => runPreset(loadDemandOfRunId)}
              disabled={
                loading ||
                runningInProgress ||
                runDisabled ||
                !preset ||
                (!!customer?.features?.EngineLoadLockedDemandForecast &&
                  !calculateDemand &&
                  loadDemandOfRunId === null)
              }
            >
              Run Atomic with Preset
            </Button>
            {!!customer?.features?.EngineLoadLockedDemandForecast &&
              !calculateDemand &&
              loadDemandOfRunId === null && (
                <FormHelperText>
                  Please select an old run that you want to use for the new
                  calculation.
                </FormHelperText>
              )}
          </FormControl>
        </>
      )}
    </RightPaneFrame>
  );
};
