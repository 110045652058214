import { useContext, useEffect, useMemo, useState } from "react";
import { InventoryOnHandTotalBeanCogsComponent } from "./component";
import { InventoryOnHandData, RunData } from "../inventory_on_hand_data";
import { TotalCogs } from "./total_cogs";
import { SkuTotalCogs } from "./sku_total_cogs";
import { ApiContext } from "../../../contexts/api/context";
import { useGetMyInventoryOnHandBeansUrlsQuery } from "../../../services/api/__generated__/backend_gateway-types";
import { WorkflowsContext } from "../../../contexts/workflows/context";

export const InventoryOnHandTotalBeanCogsContainer = () => {
  const { apiService } = useContext(ApiContext);
  const [dataLoading, setDataLoading] = useState<{ [key: string]: boolean }>(
    {}
  );

  const inventoryOnHandBeansDataLoading =
    Object.values(dataLoading).includes(true);

  const { isLoading } = useContext(WorkflowsContext);
  const [totalCogs, setTotalCogs] = useState<TotalCogs[]>([]);
  const [skuTotalCogs, setSkuTotalCogs] = useState<SkuTotalCogs[]>([]);

  const inventoryOnHandBeansData = useMemo(
    () => new InventoryOnHandData(setDataLoading),
    []
  );

  const {
    loading: inventoryOnHandBeansUrlsLoading,
    error: inventoryOnHandBeansUrlsError,
  } = useGetMyInventoryOnHandBeansUrlsQuery({
    client: apiService.getClient(),
    onCompleted(data) {
      if (data.getMyInventoryOnHandBeansUrls) {
        inventoryOnHandBeansData.setAndFetchDataUrls(
          data.getMyInventoryOnHandBeansUrls.map((i) => {
            return {
              runId: i.id,
              url: i.url,
              date: new Date(i.date),
              data: null,
            } as RunData;
          })
        );
      }
    },
  });

  useEffect(() => {
    if (inventoryOnHandBeansDataLoading === false && isLoading === false) {
      const newTotalCogs: TotalCogs[] = [];
      const newSkuTotalCogs: SkuTotalCogs[] = [];
      inventoryOnHandBeansData.getData().forEach((runData) => {
        const totalCogs = (runData.data?.map((d) => d.total_cogs) || []).reduce(
          (s, i) => s + i,
          0
        );
        newTotalCogs.push({
          date: runData.date,
          totalCogs,
        });
        runData.data?.forEach((d) => {
          newSkuTotalCogs.push({
            date: runData.date,
            totalCogs: d.total_cogs,
            sku: d.sku,
            location: d.location,
            unitCogs: d.unit_cogs,
            quantityOnHand: d.quantity,
          });
        });
      });
      setTotalCogs(() => newTotalCogs);
      setSkuTotalCogs(() => newSkuTotalCogs);
    }
  }, [inventoryOnHandBeansDataLoading, isLoading, inventoryOnHandBeansData]);

  const downloadSummaryCsvBlob = () => {
    const csv = [
      "date,total_cogs",
      ...totalCogs
        .filter((stc) => stc.date !== undefined)
        .map((stc) =>
          [stc.date?.toISOString().split("T")[0], `${stc?.totalCogs}`].join(",")
        ),
    ].join("\r\n");
    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", "total_cogs_summary.csv");
    pom.click();
    pom.remove();
  };

  const downloadDetailedCsvBlob = () => {
    const csv = [
      "date,sku,location,total_cogs,unit_cogs,quantity_on_hand",
      ...skuTotalCogs
        .filter((stc) => stc.date !== undefined && stc.sku !== undefined)
        .map((stc) =>
          [
            stc.date?.toISOString().split("T")[0],
            '"' + `${stc.sku}`.replaceAll('"', '""') + '"',
            '"' + `${stc.location}`.replaceAll('"', '""') + '"',
            `${stc.totalCogs}`,
            `${stc.unitCogs}`,
            `${stc.quantityOnHand}`,
          ].join(",")
        ),
    ].join("\r\n");
    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", "total_cogs_detailed.csv");
    pom.click();
    pom.remove();
  };

  if (inventoryOnHandBeansUrlsError) {
    console.error(
      "InventoryOnHandContainer: useGetMyInventoryOnHandBeansUrlsQuery fetch error",
      inventoryOnHandBeansUrlsError
    );
  }

  return (
    <InventoryOnHandTotalBeanCogsComponent
      totalCogs={totalCogs}
      loading={
        inventoryOnHandBeansDataLoading || inventoryOnHandBeansUrlsLoading
      }
      downloadSummaryCsvBlob={downloadSummaryCsvBlob}
      downloadDetailedCsvBlob={downloadDetailedCsvBlob}
    />
  );
};
