import { useContext, useEffect } from "react";
import { DemandDiffControlComponent } from "./control_component";
import { DiffParams, SalesMeasure } from "../diff_params";
import { PeriodType } from "../period_type";
import { DemandFromCsv } from "../demand_from_csv";
import { ApiContext } from "../../../contexts/api/context";
import { useGetMyRunComparisonRunIdsQuery } from "../../../services/api/__generated__/backend_gateway-types";
import { WorkflowsContext } from "../../../contexts/workflows/context";

export const DemandDiffControlContainer = (props: {
  diffParams: DiffParams;
  updateDiffParams: (params: DiffParams) => void;
  demand0: DemandFromCsv;
  demand1: DemandFromCsv;
  pivotObject: any;
}) => {
  const { diffParams, updateDiffParams, pivotObject } = props;
  const { allRuns, isLoading } = useContext(WorkflowsContext);

  const { apiService } = useContext(ApiContext);
  const {
    data: runIdsData,
    loading: runIdsLoading,
    error: runIdsError,
  } = useGetMyRunComparisonRunIdsQuery({
    client: apiService.getClient(),
  });

  const updateParams = (params: DiffParams) => {
    updateDiffParams({
      ...params,
      salesMeasure: params.salesMeasure || SalesMeasure.QUANTITY,
    });
  };

  const updateRuns = (params: DiffParams) => {
    const run0 = allRuns.find(
      (r) => params.runId0 === (r.oldRunId === null ? "" : `${r.oldRunId}`)
    );
    const run1 = allRuns.find(
      (r) => params.runId1 === (r.oldRunId === null ? "" : `${r.oldRunId}`)
    );
    updateDiffParams({
      ...params,
      ...(params.runId0 !== null &&
        params.runId1 === null && {
          minDate: run0?.createdAt ? new Date(run0?.createdAt) : null,
          maxDate: run0?.createdAt
            ? new Date(new Date(run0.createdAt).getUTCFullYear(), 11, 31)
            : null,
        }),
      ...(params.runId0 !== null &&
        params.runId1 !== null && {
          minDate: run1?.createdAt ? new Date(run1?.createdAt) : null,
          // NOTE: we don't have the run1 data yet, and for that reason the
          // maxDate is unknown yet. Setting the maxDate to null, and
          // setting it later with a useEffect.
          maxDate: new Date(
            Math.min(
              new Date(
                JSON.parse(run0?.runParamsJson || "{}")
                  .sales_forecast_end_date || "2025-12-31"
              ).getTime(),
              new Date(
                JSON.parse(run1?.runParamsJson || "{}")
                  .sales_forecast_end_date || "2025-12-31"
              ).getTime()
            )
          ),
          heatMap: params.heatMap === null ? true : params.heatMap,
        }),
      aggregatePeriodType: PeriodType.MONTH,
      salesMeasure: params.salesMeasure || SalesMeasure.QUANTITY,
    });
  };

  useEffect(() => {
    if (
      diffParams.maxDate === null &&
      diffParams.runId0 !== null &&
      diffParams.runId1 !== null
    ) {
      const run0 = allRuns.find(
        (r) =>
          diffParams.runId0 === (r.oldRunId === null ? "" : `${r.oldRunId}`)
      );
      const run1 = allRuns.find(
        (r) =>
          diffParams.runId1 === (r.oldRunId === null ? "" : `${r.oldRunId}`)
      );
      updateDiffParams({
        ...diffParams,
        maxDate: new Date(
          Math.min(
            new Date(
              JSON.parse(run0?.runParamsJson || "{}").orderSimulationEndDate ||
                "2024-12-31"
            ).getTime(),
            new Date(
              JSON.parse(run1?.runParamsJson || "{}").orderSimulationEndDate ||
                "2024-12-31"
            ).getTime()
          )
        ),
      });
    }
  }, [diffParams, allRuns, updateDiffParams]);

  const excelExport = () => {
    if (pivotObject?.excelExport) {
      pivotObject.excelExport({
        fileName: `atomic-run-comparison-${diffParams.runId0}-${diffParams.runId1}.xlsx`,
      });
    }
  };

  if (runIdsError) {
    console.error(
      "DemandDiffControlContainer: useGetMyLockedDemandOutputRunIdsQuery fetch error",
      runIdsError
    );
  }

  return (
    <DemandDiffControlComponent
      diffParams={diffParams}
      updateDiffParams={updateParams}
      updateRuns={updateRuns}
      loading={isLoading || runIdsLoading}
      error={null}
      runs={allRuns.filter(
        (r) =>
          r.oldRunId &&
          runIdsData?.getMyRunComparisonRunIds?.includes(r.oldRunId)
      )}
      excelExport={excelExport}
    />
  );
};
