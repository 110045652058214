import { useContext } from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { ApiContext } from "../../../../../contexts/api/context";
import { AppearanceContext } from "../../../../../contexts/appearance/context";
import { DrawerContext } from "../../../../../contexts/drawer/context";
import {
  useGetMyCustomerQuery,
  useWhoAmIQuery,
} from "../../../../../services/api/__generated__/backend_gateway-types";
import { DrawerComponent } from "./component";
import { ROUTES } from "../../../../../routes";

export const DrawerContainer = (props: { drawerWidth: number }) => {
  const { drawerWidth } = props;
  const { apiService, selectedCustomerId } = useContext(ApiContext);
  const { closeDrawer } = useContext(DrawerContext);
  const { demoMode } = useContext(AppearanceContext);
  const {
    data: whoAmIData,
    loading: whoAmILoading,
    error: whoAmIError,
  } = useWhoAmIQuery({
    client: apiService.getClient(),
  });

  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useGetMyCustomerQuery({
    client: apiService.getClient(),
  });

  const navigate = useNavigate();
  const location = useLocation();

  const match = matchRoutes(Object.values(ROUTES), location);
  let currentPathName: string | null = null;
  if (match !== null) {
    const [{ route }] = match;
    const matchedPathEntry = Object.entries(ROUTES).find(
      (i) => i[1].path === route.path
    );
    currentPathName = matchedPathEntry ? matchedPathEntry[0] : null;
  }

  const navigateToRoot = () => {
    closeDrawer();
    navigate("/", { replace: true });
  };

  const navigateToRunComparison = () => {
    closeDrawer();
    navigate("/run_comparison", { replace: true });
  };

  const navigateToForecastAccuracy = () => {
    closeDrawer();
    navigate("/forecast_accuracy", { replace: true });
  };

  const navigateToInventoryOnHand = () => {
    closeDrawer();
    navigate("/inventory_on_hand", { replace: true });
  };

  const navigateToInventoryOnHandForecast = () => {
    closeDrawer();
    navigate("/inventory_on_hand_forecast", { replace: true });
  };

  const navigateToRunHistory = () => {
    closeDrawer();
    navigate("/run_history", { replace: true });
  };

  const navigateToRuns = () => {
    closeDrawer();
    navigate("/runs", { replace: true });
  };

  const navigateToEnginePresets = () => {
    closeDrawer();
    navigate("/engine_presets", { replace: true });
  };

  const navigateToRunStatuses = () => {
    closeDrawer();
    navigate("/run_statuses", { replace: true });
  };

  const navigateToCustomers = () => {
    closeDrawer();
    navigate("/customers", { replace: true });
  };

  const navigateToAllRuns = () => {
    closeDrawer();
    navigate("/all_runs", { replace: true });
  };

  const navigateToProfile = () => {
    closeDrawer();
    navigate("/profile", { replace: true });
  };

  const navigateToPlanOfRecord = () => {
    closeDrawer();
    navigate("/plan_of_record", { replace: true });
  };

  const navigateToValidationDashboard = () => {
    closeDrawer();
    navigate("/validation_dashboard", { replace: true });
  };

  const navigateToPlanning = () => {
    closeDrawer();
    navigate("/planning", { replace: true });
  };

  const navigateToOperations = () => {
    closeDrawer();
    navigate("/operations", { replace: true });
  };

  const navigateToPOBox = () => {
    closeDrawer();
    navigate("/pos?include_status=ATOMIC_SUGGESTION,CUSTOMER_OWNED", {
      replace: true,
    });
  };

  const navigateToPOBoxArchived = () => {
    closeDrawer();
    navigate("/pos?include_status=COMPLETED,CANCELED", {
      replace: true,
    });
  };

  const navigateToPOBoxSnoozed = () => {
    closeDrawer();
    navigate("/pos?include_status=CUSTOMER_OWNED&snoozed=true", {
      replace: true,
    });
  };

  // Goodchop specific navigation
  const navigateToOdlToShipStation = () => {
    closeDrawer();
    navigate("/odl_to_shipstation", { replace: true });
  };

  // Goodchop specific navigation
  const navigateToGrossOdlGeneration = () => {
    closeDrawer();
    navigate("/gross_odl_generation", { replace: true });
  };

  // Internal dash specific navigation
  const navigateToRunLoginDash = () => {
    closeDrawer();
    navigate("/runs-logins", { replace: true });
  };

  if (whoAmIError) {
    console.error("DrawerContainer: error fetching WhoAmI", whoAmIError);
  }

  if (customerError) {
    console.error(
      "RunTriggerContainer: useGetMyCustomerQuery fetch error",
      customerError
    );
  }
  const customerId =
    whoAmIData?.whoAmI?.role === "ROOT"
      ? selectedCustomerId
      : whoAmIData?.whoAmI?.customerId || null;

  return (
    <DrawerComponent
      navigateToRoot={navigateToRoot}
      navigateToRunHistory={navigateToRunHistory}
      navigateToRuns={navigateToRuns}
      navigateToRunComparison={navigateToRunComparison}
      navigateToForecastAccuracy={navigateToForecastAccuracy}
      navigateToInventoryOnHand={navigateToInventoryOnHand}
      navigateToInventoryOnHandForecast={navigateToInventoryOnHandForecast}
      navigateToEnginePresets={navigateToEnginePresets}
      navigateToRunStatuses={navigateToRunStatuses}
      navigateToCustomers={navigateToCustomers}
      navigateToAllRuns={navigateToAllRuns}
      navigateToProfile={navigateToProfile}
      navigateToPlanOfRecord={navigateToPlanOfRecord}
      navigateToValidationDashboard={navigateToValidationDashboard}
      navigateToPlanning={navigateToPlanning}
      navigateToOperations={navigateToOperations}
      navigateToOdlToShipStation={navigateToOdlToShipStation}
      navigateToGrossOdlGeneration={navigateToGrossOdlGeneration}
      navigateToRunLoginDash={navigateToRunLoginDash}
      navigateToPOBox={navigateToPOBox}
      navigateToPOBoxSnoozed={navigateToPOBoxSnoozed}
      navigateToPOBoxArchived={navigateToPOBoxArchived}
      currentPathName={currentPathName}
      selectedCustomerId={customerId}
      userName={whoAmIData?.whoAmI?.name || null}
      email={whoAmIData?.whoAmI?.email || null}
      role={whoAmIData?.whoAmI?.role || null}
      picture={whoAmIData?.whoAmI?.picture || null}
      loading={customerLoading || whoAmILoading}
      drawerWidth={drawerWidth}
      hasEngineRunHistory={
        customerData?.getMyCustomer?.features?.EngineRunHistory || false
      }
      hasEnginePresets={
        customerData?.getMyCustomer?.features?.EnginePresets || false
      }
      hasRunComparison={
        customerData?.getMyCustomer?.features?.RunComparison || false
      }
      hasForecastAccuracy={
        customerData?.getMyCustomer?.features?.ForecastAccuracy || false
      }
      hasInventoryOnHand={
        customerData?.getMyCustomer?.features?.InventoryOnHand || false
      }
      hasInventoryOnHandForecast={
        customerData?.getMyCustomer?.features?.InventoryOnHandForecast || false
      }
      hasRun={customerData?.getMyCustomer?.features?.EngineRuns || false}
      hasValidationDashboard={
        customerData?.getMyCustomer?.features?.ValidationDashboard || false
      }
      hasFeatureStepOrchestrator={
        customerData?.getMyCustomer?.features?.StepsRunDetails || false
      }
      hasPoInbox={customerData?.getMyCustomer?.features?.PoInbox || false}
      demoMode={demoMode}
    />
  );
};
