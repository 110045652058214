import { useContext, useEffect, useState } from "react";
import { InventoryOnHandData } from "../inventory_on_hand_data";
import { StockOutRate } from "./stock_out_rate";
import { SkuStockOutRate } from "./sku_stock_out_rate";
import { InventoryOnHandStockOutRateComponent } from "./component";
import { ApiContext } from "../../../contexts/api/context";
import { useGetMyCustomerQuery } from "../../../services/api/__generated__/backend_gateway-types";
import { WorkflowsContext } from "../../../contexts/workflows/context";

const InStockThresholdDays: number = 7;

export const InventoryOnHandStockOutRateContainer = (props: {
  inventoryOnHandData: InventoryOnHandData;
  inventoryOnHandDataLoading: boolean;
}) => {
  const { inventoryOnHandData, inventoryOnHandDataLoading } = props;
  const { isLoading } = useContext(WorkflowsContext);
  const [stockOutRate, setStockOutRate] = useState<StockOutRate[]>([]);
  const [skuStockOutRate, setSkuStockOutRate] = useState<SkuStockOutRate[]>([]);

  const { apiService } = useContext(ApiContext);
  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useGetMyCustomerQuery({
    client: apiService.getClient(),
  });

  const hasThreshold =
    customerData?.getMyCustomer?.features?.InventoryOnHandInStockTreshold ||
    false;

  useEffect(() => {
    if (
      inventoryOnHandDataLoading === false &&
      isLoading === false &&
      customerLoading === false
    ) {
      const newStockOutRate: StockOutRate[] = [];
      const newSkuStockOutRate: SkuStockOutRate[] = [];
      inventoryOnHandData.getData().forEach((runData) => {
        // let totalEligibleSkus: number = 0;
        // let totalEligibleSkusInStock: number = 0;
        let totalEligibleSalesCt: number = 0;
        let totalEligibleSalesCtInStock: number = 0;
        runData.data?.forEach((d) => {
          let inStockQuantityThreshold: number = hasThreshold
            ? (d.sales_ct / 180) * InStockThresholdDays
            : 0;
          const isReorderSku = d.is_reorder_sku === 1;
          const isSold = d.is_sold_sku === 1;
          const isInStock = isReorderSku && isSold && d.quantity > 0;
          const isInStockThreshold =
            isReorderSku && isSold && d.quantity > inStockQuantityThreshold;
          newSkuStockOutRate.push({
            date: runData.date,
            sku: d.sku,
            location: d.location,
            quantity: d.quantity,
            isEligibleForInstockCalc: isReorderSku && isSold,
            isReorderSku,
            isInStock,
            isSold,
            last180daysSales: d.sales_ct,
            inStockQuantityThreshold,
            isInStockThreshold,
          });

          if (isReorderSku && isSold) {
            // totalEligibleSkus += 1;
            totalEligibleSalesCt += d.sales_ct;
            // if (isInStock) {
            if (isInStockThreshold) {
              // totalEligibleSkusInStock += 1;
              totalEligibleSalesCtInStock += d.sales_ct;
            }
          }
        });
        newStockOutRate.push({
          date: runData.date,
          inStockRate:
            // totalEligibleSkus !== 0
            //   ? totalEligibleSkusInStock / totalEligibleSkus
            totalEligibleSalesCt !== 0
              ? totalEligibleSalesCtInStock / totalEligibleSalesCt
              : 0,
        });
      });
      setStockOutRate(() => newStockOutRate);
      setSkuStockOutRate(() => newSkuStockOutRate);
    }
  }, [
    inventoryOnHandData,
    inventoryOnHandDataLoading,
    isLoading,
    customerLoading,
    hasThreshold,
  ]);

  const downloadSummaryCsvBlob = () => {
    const csv = [
      "date,in_stock_rate",
      ...stockOutRate
        .filter((sor) => sor.date !== undefined)
        .map((sor) =>
          [sor.date?.toISOString().split("T")[0], `${sor?.inStockRate}`].join(
            ","
          )
        ),
    ].join("\r\n");
    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", "in_stock_rate_summary.csv");
    pom.click();
    pom.remove();
  };

  const downloadDetailedCsvBlob = () => {
    const csv = [
      "date,sku,location,quantity_on_hand,is_reorder_sku,is_sold_sku,last_180_days_sales,calc:in_stock_quantity_threshold,calc:is_in_stock,calc:is_eligible_for_instock_calc",
      ...skuStockOutRate
        .filter((sor) => sor.date !== undefined && sor.sku !== undefined)
        .map((sor) =>
          [
            sor.date?.toISOString().split("T")[0],
            '"' + `${sor.sku}`.replaceAll('"', '""') + '"',
            '"' + `${sor.location}`.replaceAll('"', '""') + '"',
            `${sor?.quantity}`,
            `${sor?.isReorderSku ? 1 : 0}`,
            `${sor?.isSold ? 1 : 0}`,
            `${sor?.last180daysSales}`,
            `${sor?.inStockQuantityThreshold}`,
            `${sor?.isInStockThreshold ? 1 : 0}`,
            `${sor?.isEligibleForInstockCalc ? 1 : 0}`,
          ].join(",")
        ),
    ].join("\r\n");
    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", "in_stock_rate_detailed.csv");
    pom.click();
    pom.remove();
  };

  if (customerError) {
    console.error(
      "InventoryOnHandStockOutRateContainer: useGetMyCustomerQuery fetch error",
      customerError
    );
  }

  return (
    <InventoryOnHandStockOutRateComponent
      stockOutRate={stockOutRate}
      loading={inventoryOnHandDataLoading}
      downloadSummaryCsvBlob={downloadSummaryCsvBlob}
      downloadDetailedCsvBlob={downloadDetailedCsvBlob}
    />
  );
};
